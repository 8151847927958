import React from "react";

export default function Button({
  isDarkTheme = false,
  onClick,
  className,
  children,
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${
        !isDarkTheme ? "bg-grey-600" : "bg-grey-300"
      } hover:bg-grey-500 active:bg-grey-400 p-2 rounded-md disabled:text-grey-300`}
    >
      {children}
    </button>
  );
}
