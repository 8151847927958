import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import * as Tone from "tone";

export function useFullScreen() {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full screen
      document.documentElement
        .requestFullscreen()
        .then(() => setIsFullScreen(true))
        .catch((e) => {
          console.error(`Failed to enter full-screen mode: ${e.message}`);
        });
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => setIsFullScreen(false))
          .catch((e) => {
            console.error(`Failed to exit full-screen mode: ${e.message}`);
          });
      }
    }
  };

  useEffect(() => {
    const exitHandler = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setIsFullScreen(false);
      }
    };

    if (document.addEventListener) {
      document.addEventListener("webkitfullscreenchange", exitHandler, false);
      document.addEventListener("mozfullscreenchange", exitHandler, false);
      document.addEventListener("fullscreenchange", exitHandler, false);
      document.addEventListener("MSFullscreenChange", exitHandler, false);
    }

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  return { isFullScreen, toggleFullScreen };
}

export const useHover = () => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);

  return {
    isHovered,
    onMouseEnter,
    onMouseLeave,
  };
};

export const useAuthParam = () => {
  const query = new URLSearchParams(useLocation().search);
  const authToken = query.get("auth");

  const [isAdmin, setIsAdmin] = useState("");

  useEffect(() => {
    if (authToken) {
      setIsAdmin(true);
    }
  }, [authToken]);

  return {
    authToken,
    isAdmin,
  };
};

export const useController = () => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [turnOnSwitch, setTurnOnSwitch] = useState(true);
  const [isBypassed, setIsBypassed] = useState(false);
  const [isEQGraphOpen, setEQGraphOpen] = useState(false);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);
  const [volume, setVolume] = useState(1);
  const [listAudio, setListAudio] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  const handlePlayPause = () => {
    if (!isPlaying) {
      Tone.start(); // Required to unlock audio context
      playerRef.current.start();
      setIsPlaying(true);
    } else {
      playerRef.current.stop();
      setIsPlaying(false);
    }
  };

  const onChangeFileName = (filterRef) => {
    setLoaded(false);
    if (playerRef && playerRef.current) {
      playerRef.current.stop();
      playerRef.current.dispose();
    }

    playerRef.current = new Tone.Player({
      url: `/audios/${listAudio[currentAudioIndex].filename}`,
      loop: true, // Change to false if you don't want a loop
      autostart: false,
      onload: () => {
        setDuration(playerRef.current.buffer.duration); // Safely set duration after loading
        setLoaded(true)
      },
    }).connect(filterRef.current);

    if (isPlaying) {
      setIsPlaying(false);
    }
  };

  const handleByPass = (filterRef) => {
    // handle bypass logic
    if (isBypassed) {
      // Reconnect the player to the current effect node
      playerRef.current.disconnect();
      playerRef.current.connect(filterRef.current);
    } else {
      // Bypass the effect node by directly connecting the player to the destination
      playerRef.current.disconnect();
      playerRef.current.toDestination();
    }
    setIsBypassed(!isBypassed);
  };

  const handleVolume = (vol) => {
	//use Tone's destination gain as master volume
    const newVolume = parseFloat(vol);
    setVolume(newVolume);
    Tone.getDestination().volume.rampTo(newVolume, 0.05);
  }

  return {
    playerRef,
    isBypassed,
    isAboutVisible,
    turnOnSwitch,
    isEQGraphOpen,
    volume,
    currentAudioIndex,
    duration,
    currentTime,
    isPlaying,
    listAudio,
    isLoaded,
    handleVolume,
    handleByPass,
    handlePlayPause,
    setListAudio,
    onChangeFileName,
    setIsPlaying,
    setCurrentTime,
    setDuration,
    setCurrentAudioIndex,
    setVolume,
    setEQGraphOpen,
    setIsBypassed,
    setIsAboutVisible,
    setTurnOnSwitch,
  };
};
