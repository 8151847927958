import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import Header from "../../components/Header/Header";
import Dial from "../../components/Dial/Dial";

import Tone from "../../services/ToneLoader";
import { getEffectPlayerData } from "../../services/players";
import { useAuthParam, useController } from "../../hooks/custom-hooks";

const CompressorPlayer = () => {
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { isAdmin } = useAuthParam();
  const {
    playerRef,
    isBypassed,
    isAboutVisible,
    isEQGraphOpen,
    currentAudioIndex,
    isPlaying,
    currentTime,
    duration,
    volume,
    listAudio,
    isLoaded,
    handleVolume,
    handleByPass,
    handlePlayPause,
    setListAudio,
    onChangeFileName,
    setCurrentTime,
    setCurrentAudioIndex,
    setIsAboutVisible,
  } = useController();

  // Compressor parameters state
  const [threshold, setThreshold] = useState(0);
  const [ratio, setRatio] = useState(12);
  const [attack, setAttack] = useState(0.003);
  const [release, setRelease] = useState("0.250");
  const [knee, setKnee] = useState(0);
  const [makeup, setMakeup] = useState(0);

  const compressorRef = useRef(null);

  const initialSetup = async (id) => {
    const { data } = await getEffectPlayerData("compressor", id);
    setListAudio(data.audio_files);

    if (data && data.audio_files && data.audio_files.length > 0) {
      setCurrentAudioIndex(0);
    }
  };

  useEffect(() => {
    initialSetup(id);
  }, [id]);

  useEffect(() => {
    if (currentAudioIndex === -1) {
      // If the audio URL is empty, stop any existing audio and avoid creating a new player
      if (playerRef.current) {
        playerRef.current.stop();
      }
      return;
    }

    if (!compressorRef.current) {
      compressorRef.current = new Tone.RiliumCompressor({
        threshold,
        ratio,
        attack,
        release,
        knee,
        makeup,
      }).toDestination();
    }

    onChangeFileName(compressorRef);
  }, [currentAudioIndex]);

  useEffect(() => {
    return () => {
      // Clean up Tone objects when the component is unmounted
      playerRef?.current?.dispose();
      compressorRef?.current?.dispose();
    };
  }, []);

  const onHandleBypass = () => handleByPass(compressorRef);

  const handleThresholdChange = (value) => {
    const newThreshold = parseInt(value);
    setThreshold(newThreshold);

    if (newThreshold > -1 || compressorRef.current.threshold.value === 0) {
      compressorRef.current.threshold.value = newThreshold;
    } else {
      compressorRef.current.threshold.rampTo(newThreshold, 0.05);
    }
  };

  const handleRatioChange = (value) => {
    const newV = parseInt(value);
    setRatio(newV);

    compressorRef.current.ratio.rampTo(newV, 0.05);
  };

  const handleAttackChange = (value) => {
    const newV = parseFloat(value);
    setAttack(newV);

    compressorRef.current.attack.rampTo(newV, 0.05);
  };

  const handleReleaseChange = (value) => {
    const newV = parseFloat(value);
    setRelease(newV);

    compressorRef.current.release.rampTo(newV, 0.05);
  };

  const handleKneeChange = (value) => {
    const newV = parseInt(value);
    setKnee(newV);

    compressorRef.current.knee.rampTo(newV, 0.05);
  };

  const handleMakeupChange = (value) => {
    const newV = parseInt(value);
    setMakeup(newV);

    compressorRef.current.makeupGain.volume.rampTo(newV, 0.05);
  };

  return (
    <PlayerContainer>
      <Header
        label={"Compressor"}
        turnOnSwitch={!isBypassed}
        onSwitch={onHandleBypass}
        isAdmin={isAdmin}
      />
      <div
        className={`flex flex-col max-h-[calc(100vh-156px)] w-full h-full p-2 ${
          !isEQGraphOpen ? "overflow-auto" : "overflow-hidden"
        }`}
      >
        <div className="flex m-auto">
          <div>
            <div className="flex gap-4 p-2">
              <Dial
                label="Threshold"
                size={80}
                degrees={260}
                min={-100}
                max={0}
                step={1}
                value={threshold}
                color={true}
                onChange={handleThresholdChange}
              />
              <Dial
                label="Ratio"
                size={80}
                degrees={260}
                min={1}
                max={20}
                step={1}
                value={ratio}
                color={true}
                onChange={handleRatioChange}
              />
              <Dial
                label="Attack"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={attack}
                color={true}
                onChange={handleAttackChange}
              />
              <Dial
                label="Release"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={release}
                color={true}
                onChange={handleReleaseChange}
              />
              <Dial
                label="Knee"
                size={80}
                degrees={260}
                min={0}
                max={40}
                step={1}
                value={knee}
                color={true}
                onChange={handleKneeChange}
              />
              <Dial
                label="Makeup"
                size={80}
                degrees={260}
                min={0}
                max={50}
                step={1}
                value={makeup}
                color={true}
                onChange={handleMakeupChange}
              />
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      <AudioPlayerController
        readyPlay={isLoaded}
        isAboutVisible={isAboutVisible}
        audioIsPlaying={isPlaying}
        onPlayClick={handlePlayPause}
        onAboutClick={() => setIsAboutVisible(!isAboutVisible)}
        listAudio={listAudio}
        onVolumeChange={handleVolume}
        volume={volume}
        volumeMin={0}
        volumeMax={2}
        //onSeekTo={handleSeekClick}
        currentAudioIndex={currentAudioIndex}
        handleAudioItem={setCurrentAudioIndex}
      />
      <AboutContainer
        isVisible={isAboutVisible}
        onClose={() => setIsAboutVisible(!isAboutVisible)}
      >
        <h2 className="text-2xl font-bold mb-4">Compressor</h2>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
    </PlayerContainer>
  );
};

export default CompressorPlayer;
