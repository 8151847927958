import { WEQ8Runtime } from "weq8";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import SavePEQFilterModal from "../../components/SavePEQFilterModal/SavePEQFilterModal";
import Header from "../../components/Header/Header";
import NotFoundData from "../../components/NotFoundData/NotFoundData";

import { getPEQPlayerData, setPEQPlayerData } from "../../services/players";
import { useAuthParam } from "../../hooks/custom-hooks";
import { AudioContext } from "standardized-audio-context";
import "weq8/ui";
import { toast } from "react-toastify";

export default function PEQPlayer() {
  const { id } = useParams();
  const { isAdmin } = useAuthParam();
  //Player Audio variables

  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [listAudio, setListAudio] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);
  const [isSaveAs, setIsSaveAs] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1.0);
  const [duration, setDuration] = useState("00:00");
  const [isEQGraphOpen, setEQGraphOpen] = useState(false);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [filter, setFilter] = useState(-1);
  const [presets, setPresets] = useState([]);
  const [byPass, setByPass] = useState(false);
  const [weq8ref, setWeq8] = useState();

  const audioPlayerRef = useRef(null);
  const audioContextRef = useRef(null);
  const mediaElementSourceRef = useRef(null);

  const toggleAbout = () => setIsAboutVisible(!isAboutVisible);
  const visualisationNode = document
    .querySelector("weq8-ui")
    ?.shadowRoot.querySelector(".visualisation");
  const filterNode = document
    .querySelector("weq8-ui")
    ?.shadowRoot.querySelector(".filters");
  const selectedTouchNode = visualisationNode?.querySelectorAll(
    ".filter-handle-positioner > .filter-handle"
  );

  const selectedRowhNode = filterNode?.querySelectorAll("weq8-ui-filter-row");

  const selectedTouchIndex = Array.from(selectedTouchNode ?? []).findIndex(
    (element) => element?.classList.contains("selected")
  );
  const selectedRowIndex = Array.from(selectedRowhNode ?? []).findIndex(
    (element) => element?.classList.contains("selected")
  );

  //  console.log(selectedRowIndex);
  //Load Data on first page load
  const fetchData = async (id) => {
    try {
      const { data } = await getPEQPlayerData(id);

      if (data.audio_files) {
        setListAudio(data.audio_files);
      } else {
        setShowError(true);
      }

      if (data.type_config) {
        setPresets(data.type_config);
      } else {
        setShowError(true);
      }

      if (data.audio_files.length === 0) {
        setShowError(true);
      }
    } catch (e) {
      setShowError(true);
    }
  };

  useEffect(() => {
    //Fetch Audio List
    fetchData(id);
  }, [id]);

  useEffect(() => {
    async function loadFirstAudio() {
      if (listAudio.length > 0) {
        await loadNewTrack(currentAudioIndex > -1 ? currentAudioIndex : 0);
        setIsPlaying(false);
      }
    }

    loadFirstAudio();
  }, [listAudio]);

  const copyCanvas = () => {
    const sourceCanvas = document
      .querySelector("weq8-ui")
      .shadowRoot.querySelector(".visualisation")
      .getElementsByClassName("analyser")[0];
    const destCanvas = document.getElementsByClassName("copy")[0];

    const canvasWidth = sourceCanvas.width;
    const canvasHeight = sourceCanvas.height;

    destCanvas.setAttribute("width", canvasWidth);
    destCanvas.setAttribute("height", canvasHeight);

    const destCanvasContext = destCanvas.getContext("2d");
    destCanvasContext.clearRect(0, 0, destCanvas.width, destCanvas.height);
    destCanvasContext.drawImage(sourceCanvas, 0, 0);
  };

  const togglePlayback = () => {
    if (!audioContextRef || !audioContextRef.current) {
      initAudioContext();
    }
    if (audioPlayerRef && currentAudioIndex > -1) {
      if (!isPlaying) {
        audioPlayerRef.current.play();
        setIsPlaying(true);
      } else {
        audioPlayerRef.current.pause();
        setIsPlaying(false);
        if (isEQGraphOpen) copyCanvas();
      }
    } else {
      if (currentAudioIndex === -1) {
        loadNewTrack(0);
      }
    }
  };
  const handleVolumeChange = (value) => {
    setVolume(Number(value));
    audioPlayerRef.current.volume = Number(value);
  };
  const toggleEQ = () => {
    if (isEQGraphOpen && visualisationNode) {
      visualisationNode.style.visibility = "hidden";
      filterNode.style.display = "grid";
    } else {
      visualisationNode.style.visibility = "visible";
      filterNode.style.display = "none";
    }

    setEQGraphOpen(!isEQGraphOpen);
  };

  //Load new Audio file
  const loadNewTrack = async (index) => {
    setCurrentAudioIndex(index);
    setCurrentTime(0);
    setIsPlaying(false);

    const newSrc = `/audios/${listAudio[index].filename}`;

    const audioElem = audioPlayerRef.current;
    audioElem.src = newSrc;
    audioElem.load();
  };

  const initAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext();
    }
    const audioContext = audioContextRef.current;

    let weq8 = weq8ref;
    if (!weq8) {
      weq8 = new WEQ8Runtime(audioContext);
      setWeq8(weq8);
    }

    if (!mediaElementSourceRef.current && audioPlayerRef.current) {
      mediaElementSourceRef.current = audioContext.createMediaElementSource(
        audioPlayerRef.current
      );

      mediaElementSourceRef.current.connect(weq8.input);
      weq8.connect(audioContext.destination);
      document.querySelector("weq8-ui").runtime = weq8;
    }
  };

  // Listen for AudioPlayerRef Events
  useEffect(() => {
    const audioElem = audioPlayerRef.current;
    audioElem.volume = volume;

    const handleTimeUpdate = () => {
      setCurrentTime(audioElem.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(audioElem.duration);
    };

    const handlePlaybackFinished = () => {
      console.log("Playback finished");
      setIsPlaying(false);
    };

    if (audioElem) {
      audioElem.addEventListener("timeupdate", handleTimeUpdate);
      audioElem.addEventListener("loadedmetadata", handleLoadedMetadata);
      audioElem.addEventListener("ended", handlePlaybackFinished);

      // Cleanup
      return () => {
        audioElem.removeEventListener("timeupdate", handleTimeUpdate);
        audioElem.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audioElem.removeEventListener("ended", handlePlaybackFinished);
      };
    }
  }, [volume]);

  const handleAudioLoad = () => {
    console.log("Audio loaded!");
  };

  const handleSeekClick = (value) => {
    console.log("Seek TO", value);
    audioPlayerRef.current.currentTime = value;
    if (isPlaying) {
      audioPlayerRef.current.play();
    }
  };
  const handleCanPlay = () => {
    setPlayerReady(true);
    initAudioContext();
    //    fillCustomStyleOnTable();
    if (presets.length > 0 && filter === -1) {
      setFilter(0);
    }
  };

  useEffect(() => {
    if (!weq8ref) return;

    Array(8)
      .fill("")
      .forEach((value, index) => {
        weq8ref.setFilterType(index, "lowpass12");
        weq8ref.setFilterFrequency(index, 0);
        weq8ref.setFilterQ(index, 0);
        weq8ref.setFilterGain(index, 0);
      });
    if (filter > -1) {
      presets[filter].filters.forEach((value, index) => {
        weq8ref.setFilterType(index, value.type);
        weq8ref.setFilterFrequency(index, value.frequency);
        weq8ref.setFilterQ(index, value.Q);
        weq8ref.setFilterGain(index, value.gain);
        weq8ref.toggleBypass(index, null);
      });
    }
  }, [filter]);

  useEffect(() => {
    if (!weq8ref) return;
    if (isEQGraphOpen) {
      weq8ref.spec.map((item, index) => {
        weq8ref.setFilterFrequency(index, item.frequency);
        weq8ref.setFilterGain(index, item.gain);
      });
    }
  }, [isEQGraphOpen]);

  useEffect(() => {
    if (!weq8ref) return;

    Array(8)
      .fill()
      .map((item, index) => {
        weq8ref.toggleBypass(index, byPass);
      });

    setTimeout(() => {
      //      fillCustomStyleOnGraph();
    }, 100);
  }, [byPass, weq8ref]);

  const fillCustomStyleOnGraph = () => {
    if (visualisationNode) {
      const elements =
        visualisationNode.getElementsByClassName("filter-handle");
      [...elements].forEach((element) => {
        element.style.backgroundColor = element.classList.contains("bypassed")
          ? "#FF5047"
          : element.classList.contains("selected")
          ? "#FFD600"
          : "#00CC8F";

        element.style.color = "white";
      });
    }
  };

  const fillCustomStyleOnTable = () => {
    filterNode.style.color = "black";
    filterNode.style.fontWeight = "bold";
    filterNode.querySelector("thead").style.gap = "10px";
    Array.from(filterNode.querySelectorAll("th")).forEach((item) => {
      item.style.border = "none";
      item.style.fontWeight = "bold";
    });
    Array.from(filterNode.querySelectorAll("weq8-ui-filter-row")).forEach(
      (item) => {
        const selected = item.classList.contains("selected");

        item.style.gap = "10px";
        item.style.border = "none";
        item.style.fontWeight = "bold";
        item.style.borderRadius = 0;
        item.style.padding = "2px 10px 2px 10px";

        // if (!selected) {
        //   item.style.color = "black";
        // } else {
        //   item.style.color = "white";
        // }

        const rowContent = item.shadowRoot;
        const chipContent = rowContent.querySelector(".chip");
        const numberContent = rowContent.querySelector(".filterNumber");

        rowContent.querySelectorAll("td").forEach((item) => {
          item.style.backgroundColor = "#373737";
          item.style.borderRadius = "15px";
          item.style.padding = "2px 0px 2px 0px";
        });

        if (rowContent.querySelector(".filterNumber")) {
          if (chipContent.classList.contains("bypassed")) {
            numberContent.style.backgroundColor = "#FF5047";
          } else if (!chipContent.classList.contains("disabled")) {
            numberContent.style.backgroundColor = "#00CC8F";
            numberContent.style.color = "#fff";
          }

          if (selected) {
            numberContent.style.backgroundColor = "#FFD600";
          }

          Array.from(rowContent.querySelectorAll("input")).forEach(
            (inputNode) => {
              if (!inputNode.disabled) {
                // if (selected) {
                //   inputNode.style.color = "#fff";
                // } else {
                //   inputNode.style.color = "#000";
                // }
                inputNode.style.fontWeight = "bold";
              }
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    if (visualisationNode && isEQGraphOpen) {
      //      fillCustomStyleOnGraph();
    }
    if (filterNode && !isEQGraphOpen) {
      //      console.log("ha", selectedRowIndex);
      //      fillCustomStyleOnTable();
    }
  }, [
    isEQGraphOpen,
    isPlaying,
    selectedRowIndex,
    selectedTouchIndex,
    filter,
    filterNode,
  ]);

  useEffect(() => {
    if (visualisationNode) {
      visualisationNode.style.backgroundColor = "#a8a8a8";
      visualisationNode.style.visibility = "hidden";
    }
  }, [visualisationNode]);

  const save = async (newPresets) => {
    let newResponse = {
      audio_files: listAudio.map((item) => item._id),
      type_config: newPresets,
    };

    await setPEQPlayerData(id, newResponse);
  };

  const handleDelete = async (index) => {
    let newPresets = [...presets];
    newPresets.splice(index, 1);
    setPresets(newPresets);

    await save(newPresets);
    await fetchData(id);
    setFilter(0);
  };

  const handleSave = () => {
    try {
      let newPresets = [...presets];

      newPresets[filter].filters = [...weq8ref.spec];
      save(newPresets);
      toast.success(`<${presets[filter].preset_name}> is updated!`);
    } catch (e) {
      toast.error(e);
    }
  };

  const handleSaveAs = async (name) => {
    try {
      let newPresets = [...presets];
      newPresets.push({
        preset_name: name,
        filters: weq8ref.spec,
      });
      await save(newPresets);
      await fetchData(id);
      loadNewTrack(currentAudioIndex);
      setFilter(presets.length);
    } catch (e) {
      toast.error(e);
    }
  };

  const onSave = () => {
    if (filter === -1) {
      setShowSaveFilterModal(true);
      setIsSaveAs(true);
    } else {
      handleSave();
      setIsSaveAs(false);
    }
  };

  const onSaveAs = () => {
    setShowSaveFilterModal(true);
    setIsSaveAs(true);
  };

  return (
    <div className="relative">
      <audio
        loop
        ref={audioPlayerRef}
        controls={false}
        onLoadedData={handleAudioLoad}
        onCanPlay={handleCanPlay}
        className="hidden w-full"
      >
        <source src="" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <PlayerContainer>
        <Header
          isDarkTheme={true}
          label="EQ"
          turnOnSwitch={!byPass}
          onSwitch={() => setByPass(!byPass)}
          toggleSwitchValue={isEQGraphOpen}
          onToggleSwitch={toggleEQ}
          isAdmin={isAdmin}
        />
        {showError ? (
          <NotFoundData />
        ) : (
          <div className="flex flex-col h-full overflow-auto bg-grey-100">
            <div className="flex flex-col w-full h-full p-2">
              <div className="relative h-full">
                <weq8-ui
                  class={`eq-filter ${isEQGraphOpen ? "rounded-none p-0" : ""}`}
                />
                {isEQGraphOpen && (
                  <div
                    className={` ${
                      isPlaying ? "invisible" : "visible"
                    } absolute bottom-0 overflow-hidden`}
                  >
                    <canvas width="1" height="1" className="copy max-w-full" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <AudioPlayerController
          readyPlay={true}
          isDarkTheme={true}
          isAboutVisible={isAboutVisible}
          audioIsPlaying={isPlaying}
          currentTime={currentTime}
          volume={volume}
          duration={duration}
          onPlayClick={togglePlayback}
          onVolumeChange={handleVolumeChange}
          onSeekTo={handleSeekClick}
          onAboutClick={toggleAbout}
          listAudio={listAudio}
          currentAudioIndex={currentAudioIndex}
          handleAudioItem={loadNewTrack}
          listPreset={presets.map((item, index) => ({
            name: item.preset_name,
            value: index,
          }))}
          currentPresetIndex={filter}
          showSave={isAdmin}
          handlePresetItem={setFilter}
          onSave={onSave}
          onSaveAs={onSaveAs}
          onDelete={handleDelete}
        />
      </PlayerContainer>
      <AboutContainer isVisible={isAboutVisible} onClose={toggleAbout}>
        <h2 className="text-2xl font-bold mb-4">PEQ Player</h2>
        <p className="mb-4">
          We'd love to hear any suggestions you have about how to improve this
          player. To make a suggestion, click here.
        </p>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
      <SavePEQFilterModal
        isSaveAs={isSaveAs}
        data={weq8ref?.spec}
        open={showSaveFilterModal}
        onSaveAs={handleSaveAs}
        onClose={() => setShowSaveFilterModal(false)}
      />
    </div>
  );
}
