import axios from "axios";
// const API_BASE_URL = "https://embed.rilium.com/api";
// const API_BASE_URL = 'http://localhost:5100/api'
const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const apiVersion = process.env.REACT_APP_VERSION;

export const getAudioCompareData = (id) => {
  return axios.get(`${API_BASE_URL}/v2/audioCompare/${id}`);
};

export const getStepSequencerData = (id) => {
  return axios.get(`${API_BASE_URL}/v2/step-sequencer/${id}`);
};

export const getPEQPlayerData = (id) => {
  return axios.get(`${API_BASE_URL}/v2/peq/${id}`);
};

export const setPEQPlayerData = (id, data) => {
  return axios.put(`${API_BASE_URL}/v2/peq/${id}`, data);
};

export const setEffectPlayerData = async (effectType, id, data) => {
  return axios.put(
    `${API_BASE_URL}/v2/${effectType.toLowerCase()}/${id}`,
    data
  );
};

export const getEffectPlayerData = async (effectType, id) => {
  return axios.get(`${API_BASE_URL}/v2/${effectType}/${id}`);
};
