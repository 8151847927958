import React, { useState } from "react";
import {
  IconChartCandle,
  IconChevronLeft,
  IconChevronRight,
  IconMenu2,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconPlaylist,
  IconVolume,
} from "@tabler/icons-react";
import { Tooltip } from "flowbite-react";

import VerticalSlider from "../Slider/VerticalSlider";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import ProgressBar from "../Slider/ProgressBar";
import OutsideClickHandler from "../OutsideHandler/OutsideHandler";
import SaveDropdownButton from "../SaveDropdownButton/SaveDropdownButton";
import { ToastContainer } from "react-toastify";
import { useAuthParam } from "../../hooks/custom-hooks";

export default function AudioPlayerController({
  isAboutVisible,
  isDarkTheme = false,
  showVolume = true,
  showSave = false,
  saveOnly = false,
  audioIsPlaying,
  currentTime,
  volume,
  volumeMin = 0,
  volumeMax = 1,
  volumeStep = .01,
  duration,
  listAudio,
  currentAudioIndex,
  handleAudioItem,
  listPreset,
  currentPresetIndex,
  currentTempo,
  onTempoChange,
  handlePresetItem,
  onPlayClick,
  onVolumeChange,
  onPrevClick,
  onNextClick,
  onSeekTo,
  onAboutClick,
  readyPlay = false,
  onSave = () => {},
  onSaveAs = () => {},
  onDelete = () => {},
}) {
  const [isVolumeSliderVisible, setVolumeSliderVisibility] = useState(false);
  const [isTempoSliderVisible, setTempoSliderVisible] = useState(false);
  const { isAdmin } = useAuthParam();

  const toggleVolumeSlider = () => {
    setVolumeSliderVisibility(!isVolumeSliderVisible);
  };

  const getMinutes = (t) => {
    const min = parseInt(t / 60);
    const sec = parseInt(t % 60);
    return `${"0" + min}:${sec < 10 ? "0" + sec : sec}`;
  };

  return (
    <>
      <div
        className={`bg-white  w-full h-fit py-2 self-end rounded-b-xl flex flex-col z-20 ${
          isDarkTheme ? "bg-gradient-to-b" : ""
        } ${isDarkTheme ? "  from-grey-100" : " to-white"} to-white `}
      >
        <div className="flex-1 flex justify-between px-2">
          <div className={`flex h-full rounded-md ${isDarkTheme ? 'bg-grey-300': 'bg-grey-600'} py-1 px-1 gap-1`}>
            <Tooltip style="light" content={audioIsPlaying ? "Pause" : "Play"}>
              <Button disabled={!readyPlay} isDarkTheme={isDarkTheme} onClick={onPlayClick}>
                {audioIsPlaying ? (
                  <IconPlayerPauseFilled className="w-6 h-6" />
                ) : (
                  <IconPlayerPlayFilled className="w-6 h-6" />
                )}
              </Button>
            </Tooltip>
            {showVolume && (
              <div
                className={`relative ${
                  isVolumeSliderVisible ? "z-50" : "z-10"
                }`}
              >
                <Tooltip style="light" content="Volume">
                  <Button
                    isDarkTheme={isDarkTheme}
                    className={`
                       ${isVolumeSliderVisible ? "shadow-md z-50" : "z-10"}`}
                    onClick={toggleVolumeSlider}
                  >
                    <IconVolume className="w-6 h-6" />
                  </Button>
                </Tooltip>
                {isVolumeSliderVisible && (
                  <div className="absolute bottom-14 left-1/2 -translate-x-1">
                    <OutsideClickHandler
                      onOutsideClick={() => toggleVolumeSlider()}
                    >
                      <VerticalSlider
                        className=""
                        min={volumeMin}
                        max={volumeMax}
                        step={volumeStep}
                        value={volume}
                        onChange={onVolumeChange}
                      />
                    </OutsideClickHandler>
                  </div>
                )}
              </div>
            )}
            {onPrevClick && (
              <Tooltip content="Previous" style="light">
                <Button isDarkTheme={isDarkTheme} onClick={onPrevClick}>
                  <IconChevronLeft />
                </Button>
              </Tooltip>
            )}
            {onNextClick && (
              <Tooltip content="Next" style="light">
                <Button isDarkTheme={isDarkTheme} onClick={onNextClick}>
                  <IconChevronRight />
                </Button>
              </Tooltip>
            )}
            {currentTempo && (
              <div
                className={`relative ${
                  isTempoSliderVisible ? "shadow-md z-50" : "z-10"
                }`}
              >
                <Tooltip
                  className="min-w-[150px] text-center"
                  content="Beats per minute"
                  style="light"
                >
                  <Button
                    isDarkTheme={isDarkTheme}
                    className={` w-[100px] ${
                      isTempoSliderVisible ? "z-50" : "z-10"
                    }`}
                    onClick={() => {
                      setTempoSliderVisible(!isTempoSliderVisible);
                    }}
                  >
                    <span className="font-bold mr-1">{currentTempo}</span>BPM
                  </Button>
                </Tooltip>
                {isTempoSliderVisible && (
                  <div className="absolute bottom-14 left-1/2">
                    <OutsideClickHandler
                      onOutsideClick={() => setTempoSliderVisible(false)}
                    >
                      <VerticalSlider
                        className=""
                        min={60}
                        max={180}
                        step={1}
                        value={currentTempo}
                        onChange={onTempoChange}
                      />
                    </OutsideClickHandler>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center rounded-full px-2 gap-2">
            {listAudio && (
              <Tooltip content="Audio" style="light">
                <Dropdown
                  isDarkTheme={isDarkTheme}
                  placeholder="Select Audio"
                  options={listAudio.map((item) => ({ name: item.filename }))}
                  currentIndex={currentAudioIndex}
                  handleOptionClickEvent={handleAudioItem}
                  mobileIcon={<IconPlaylist />}
                />
              </Tooltip>
            )}
            {listPreset && (
              <Tooltip content="Preset" style="light">
                <Dropdown
                  isDarkTheme={isDarkTheme}
                  placeholder="Select Preset"
                  options={listPreset}
                  currentIndex={currentPresetIndex}
                  handleOptionClickEvent={handlePresetItem}
                  mobileIcon={<IconChartCandle />}
                  deletable={isAdmin}
                  onDelete={onDelete}
                />
              </Tooltip>
            )}
            {showSave && (
              <Tooltip content="Save / Save As" style="light">
                <SaveDropdownButton
                  isDarkTheme={isDarkTheme}
                  saveOnly={saveOnly}
                  onSave={onSave}
                  onSaveAs={onSaveAs}
                />
              </Tooltip>
            )}
            <Tooltip content="Menu" style="light">
              <Button
                isDarkTheme={isDarkTheme}
                className={`p-3  ${isAboutVisible ? "z-50" : "z-10"}`}
                onClick={onAboutClick}
              >
                <IconMenu2 />
              </Button>
              {(isAboutVisible ||
                isTempoSliderVisible ||
                isVolumeSliderVisible) && (
                <div className="fixed inset-0 z-20 bg-grey-100 opacity-70"></div>
              )}
            </Tooltip>
          </div>
        </div>
        {onSeekTo && duration > 0 && (
          <div className="z-10 flex-1 h-full flex justify-between px-2 mt-1 items-center gap-2">
            <div className="hidden md:block">
              <span>{getMinutes(currentTime)}</span>
            </div>
            <div className="w-full">
              <ProgressBar
                isPlaying={audioIsPlaying}
                min={0}
                max={duration}
                step={duration / 10000 || 1}
                value={currentTime || 0}
                onChange={(value) => onSeekTo(value)}
              />
            </div>
            <div className="hidden md:block">
              <span>{getMinutes(duration)}</span>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
}
