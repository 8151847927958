import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import Header from "../../components/Header/Header";
import Dial from "../../components/Dial/Dial";

import Tone from "../../services/ToneLoader";
import { getEffectPlayerData } from "../../services/players";
import { useAuthParam, useController } from "../../hooks/custom-hooks";

const LimiterPlayer = () => {
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { isAdmin } = useAuthParam();
  const {
    playerRef,
    isBypassed,
    isAboutVisible,
    isEQGraphOpen,
    currentAudioIndex,
    isPlaying,
    currentTime,
    duration,
    volume,
    listAudio,
    isLoaded,
    handleVolume,
    handleByPass,
    handlePlayPause,
    setListAudio,
    onChangeFileName,
    setCurrentTime,
    setCurrentAudioIndex,
    setIsAboutVisible,
  } = useController();

  const [threshold, setThreshold] = useState(0);

  const limiterRef = useRef(null);

  const initialSetup = async (id) => {
    const { data } = await getEffectPlayerData("limiter", id);
    setListAudio(data.audio_files);

    if (data && data.audio_files && data.audio_files.length > 0) {
      setCurrentAudioIndex(0);
    }
  };

  useEffect(() => {
    initialSetup(id);
  }, [id]);

  useEffect(() => {
    if (currentAudioIndex === -1) {
      // If the audio URL is empty, stop any existing audio and avoid creating a new player
      if (playerRef.current) {
        playerRef.current.stop();
      }
      return;
    }

    if (!limiterRef.current) {
      limiterRef.current = new Tone.RiliumLimiter(threshold).toDestination();
    }

    onChangeFileName(limiterRef);
  }, [currentAudioIndex]);

  useEffect(() => {
    return () => {
      // Clean up Tone objects when the component is unmounted
      playerRef?.current?.dispose();
      limiterRef?.current?.dispose();
    };
  }, []);

  const onHandleBypass = () => handleByPass(limiterRef);

  const handleThresholdChange = (value) => {
    const newThreshold = parseInt(value);
    setThreshold(newThreshold);

    if (newThreshold > -1 || limiterRef.current.threshold.value === 0) {
      limiterRef.current.threshold.value = newThreshold;
    } else {
      limiterRef.current.threshold.rampTo(newThreshold, 0.05);
    }
  };

  const handleSeek = (value) => {
    const newTime = parseFloat(value);
    setCurrentTime(newTime); // Update local state
    playerRef.current.seek(newTime); // Seek in the player
  };

  return (
    <PlayerContainer>
      <Header
        label={"Limiter"}
        turnOnSwitch={!isBypassed}
        onSwitch={onHandleBypass}
        isAdmin={isAdmin}
      />
      <div
        className={`flex flex-col max-h-[calc(100vh-156px)] w-full h-full p-2 ${
          !isEQGraphOpen ? "overflow-auto" : "overflow-hidden"
        }`}
      >
        <div className="flex m-auto">
          <div>
            <div className="flex p-2">
              <Dial
                label="Threshold"
                size={80}
                degrees={260}
                min={-100}
                max={0}
                step={1}
                value={threshold}
                color={true}
                onChange={handleThresholdChange}
              />
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      <AudioPlayerController
        readyPlay={isLoaded}
        isAboutVisible={isAboutVisible}
        audioIsPlaying={isPlaying}
        listAudio={listAudio}
        volume={volume}
        currentAudioIndex={currentAudioIndex}
        duration={duration}
        currentTime={currentTime}
        onPlayClick={handlePlayPause}
        onAboutClick={() => setIsAboutVisible(!isAboutVisible)}
        onVolumeChange={handleVolume}
        volumeMin={0}
        volumeMax={2}
        //   onSeekTo={handleSeek}
        handleAudioItem={setCurrentAudioIndex}
      />
      <AboutContainer
        isVisible={isAboutVisible}
        onClose={() => setIsAboutVisible(!isAboutVisible)}
      >
        <h2 className="text-2xl font-bold mb-4">Limiter</h2>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
    </PlayerContainer>
  );
};

export default LimiterPlayer;
