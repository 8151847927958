import React, { useEffect, useState, useRef } from "react";
import * as Tone from "tone";

import { getEffectPlayerData } from "../../services/players";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";

import './CompressorStyles.css';

export default function Compressor() {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);
  const [effectType, id] = pathSegments;
  const [listAudio, setListAudio] = useState([]);
  const [effectPlayerType] = useState(effectType.split("-")[0]);
  const [isPlaying, setIsPlaying] = useState(false);

  // Compressor parameters state
  const [bypass, setBypass] = useState(false); // State for bypass toggle
  const [threshold, setThreshold] = useState(-24);
  const [ratio, setRatio] = useState(12);
  const [attack, setAttack] = useState(0.003);
  const [release, setRelease] = useState(0.25);
  const [knee, setKnee] = useState(30);

  // Gain state
  const [gain, setGain] = useState(1); // Initial gain (0.5 means 50%)
  // New: Volume control for the player itself
  const [volume, setVolume] = useState(-6); // Volume in dB (default to -6dB)

  // Use useRef for Tone.Player, Tone.Compressor, and Tone.Gain instances
  const playerRef = useRef(null);
  const compressorRef = useRef(null);
  const gainNodeRef = useRef(null);
  
  let reductionIntervalID;

  const fetchData = async (id) => {
    const { data } = await getEffectPlayerData(effectPlayerType, id);
    setListAudio(data.audio_files);
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const startSound = async () => {
    if (listAudio.length === 0) {
      console.error("Audio URL not available");
      return;
    }

    await Tone.start(); // Ensures Tone.js is ready to play audio

    // Create a Gain node
    gainNodeRef.current = new Tone.Gain(gain).toDestination(); // Initial gain set to 50%

    // Create a Compressor with custom settings
    compressorRef.current = new Tone.Compressor({
      threshold,
      ratio,
      attack,
      release,
      knee,
    });
	console.log(compressorRef.current);

    // Load your custom audio file
    playerRef.current = new Tone.Player({
      url: `/audios/${listAudio[0].filename}`, // Replace with your audio file path
      loop: true,
      autostart: false, // Set autostart to false to control playback manually
      onload: () => {
        // Audio is loaded, now start playback
        playerRef.current.start();
        setIsPlaying(true);
      },
      onerror: (error) => {
        console.error("Error loading audio:", error);
      },
    });

    if (bypass) {
      playerRef.current.disconnect();
      playerRef.current.toDestination(); // Bypass compressor, connect directly to speaker
    } else {
      playerRef.current.connect(compressorRef.current);
      compressorRef.current.connect(gainNodeRef.current); // Connect Compressor to Gain
    }
	
	if (!reductionIntervalID) {
		const reductionElement = document.getElementById("reductionRealtime");
		reductionIntervalID = setInterval(() => reductionElement.innerText = compressorRef.current.reduction, 500);
	}
  };

  const stopSound = () => {
    if (playerRef.current) {
      playerRef.current.stop(); // Stop the audio playback
    }
    setIsPlaying(false);
  };

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (playerRef.current) {
      playerRef.current.volume.rampTo(newVolume, .05); // Update player's volume directly
    }
  };

  // Function to handle gain (Gain) change
  const handlegainChange = (event) => {
    const value = parseFloat(event.target.value);
    setGain(value);
    if (gainNodeRef.current) gainNodeRef.current.gain.rampTo(value, 0.1); // Smoothly transition the gain value
  };

  const handleThresholdChange = (event) => {
    const value = parseInt(event.target.value);
    console.log(value)
    setThreshold(value);
    if (compressorRef.current) {
		if ( (value > -1) || (compressorRef.current.threshold.value === 0) ) {
			compressorRef.current.threshold.value = value;
		} else {
			compressorRef.current.threshold.rampTo(value, .05);
		}
	}
  };

  const handleRatioChange = (event) => {
    const value = parseFloat(event.target.value);
    setRatio(value);
    if (compressorRef.current) compressorRef.current.ratio.rampTo(value, .05);
  };

  const handleAttackChange = (event) => {
    const value = parseFloat(event.target.value);
    setAttack(value);
    if (compressorRef.current) compressorRef.current.attack.rampTo(value, .05);
  };

  const handleReleaseChange = (event) => {
    const value = parseFloat(event.target.value);
    setRelease(value);
    if (compressorRef.current) compressorRef.current.release.rampTo(value, .05);
  };

  const handleKneeChange = (event) => {
    const value = parseFloat(event.target.value);
    setKnee(value);
    if (compressorRef.current) compressorRef.current.knee.rampTo(value, .05);
  };

  const handleBypassToggle = () => {
    setBypass(!bypass);
    if (playerRef.current && compressorRef.current) {
      if (!bypass) {
        playerRef.current.disconnect(); // Disconnect from compressor
        playerRef.current.toDestination(); // Connect directly to Gain
      } else {
        playerRef.current.disconnect(); // Disconnect from speakers
        playerRef.current.connect(compressorRef.current); // Connect to compressor, then Gain
        compressorRef.current.connect(gainNodeRef.current);
      }
    }
  };

  return (
    <>
      <div>
        <div>
          <label>
            Threshold: {threshold} dB
          </label>
            <input
              type="range"
              min="-100"
              max="0"
              step="1"
              value={threshold}
              onChange={handleThresholdChange}
            />
        </div>

        <div>
          <label>
            Ratio: {ratio}:1
          </label>
            <input
              type="range"
              min="1"
              max="20"
              step="1"
              value={ratio}
              onChange={handleRatioChange}
            />
        </div>

        <div>
          <label>
            Attack: {attack} s
          </label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.001"
              value={attack}
              onChange={handleAttackChange}
            />
        </div>

        <div>
          <label>
            Release: {release} s
          </label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={release}
              onChange={handleReleaseChange}
            />
        </div>

        <div>
          <label>
            Knee: {knee} dB
          </label>
            <input
              type="range"
              min="0"
              max="40"
              step="1"
              value={knee}
              onChange={handleKneeChange}
            />
        </div>

        {/* New: gain Control */}
        <div>
          <label>
            Gain: {gain * 100}%
          </label>
            <input
              type="range"
              min="0"
              max="2"
              step="0.1"
              value={gain}
              onChange={handlegainChange}
            />
        </div>

        {/* Volume control for the player */}
        <div>
          <label>
            Volume: {volume} dB
          </label>
            <input
              type="range"
              min="-60" // Tone.js uses a decibel range, starting from -60dB (mute)
              max="10" // 10 dB is the maximum volume
              step="1"
              value={volume}
              onChange={handleVolumeChange}
            />
        </div>
      </div>
      {/* Bypass Toggle */}
      <div>
        <label>
          Bypass Compressor:
        </label>
          <input
            type="checkbox"
            checked={bypass}
            onChange={handleBypassToggle}
          />
      </div>
	  <div>
		<label>
          Reduction dB: 
        </label>
		<span id="reductionRealtime"></span>
	  </div>
      <Button onClick={isPlaying ? stopSound : startSound}>
        {isPlaying ? "Stop" : "Play"}
      </Button>
    </>
  );
}
