import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import Header from "../../components/Header/Header";
import Dial from "../../components/Dial/Dial";

import Tone from "../../services/ToneLoader";
import { getEffectPlayerData } from "../../services/players";
import { useAuthParam, useController } from "../../hooks/custom-hooks";

const BitCrusher = () => {
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { isAdmin } = useAuthParam();
  const {
    playerRef,
    isBypassed,
    isAboutVisible,
    isEQGraphOpen,
    currentAudioIndex,
    isPlaying,
    currentTime,
    duration,
    volume,
    listAudio,
    isLoaded,
    handleVolume,
    handleByPass,
    handlePlayPause,
    setListAudio,
    onChangeFileName,
    setCurrentTime,
    setCurrentAudioIndex,
    setIsAboutVisible,
  } = useController();

  const [bits, setbits] = useState(4);
  const [wet, setwet] = useState(1);

  const bitcrusherRef = useRef(null);

  const initialSetup = async (id) => {
    const { data } = await getEffectPlayerData("bitcrusher", id);
    setListAudio(data.audio_files);

    if (data && data.audio_files && data.audio_files.length > 0) {
      setCurrentAudioIndex(0);
    }
  };

  useEffect(() => {
    initialSetup(id);
  }, [id]);

  useEffect(() => {
    if (currentAudioIndex === -1) {
      // If the audio URL is empty, stop any existing audio and avoid creating a new player
      if (playerRef.current) {
        playerRef.current.stop();
      }
      return;
    }

    if (!bitcrusherRef.current) {
      bitcrusherRef.current = new Tone.BitCrusher(bits, wet).toDestination();
    }

    onChangeFileName(bitcrusherRef);
  }, [currentAudioIndex]);

  useEffect(() => {
    return () => {
      // Clean up Tone objects when the component is unmounted
      playerRef?.current?.dispose();
      bitcrusherRef?.current?.dispose();
    };
  }, []);

  const onHandleBypass = () => handleByPass(bitcrusherRef);

  const handlebitsChange = (value) => {
    const newbits = parseInt(value);
    setbits(newbits);

    bitcrusherRef.current.bits.rampTo(newbits, 0.5);
  };

  const handlewetChange = (value) => {
    const newV = parseFloat(value);
    setwet(newV);

    bitcrusherRef.current.wet.rampTo(newV, 0.5);
  };

  return (
    <PlayerContainer>
      <Header
        label={"BitCrusher"}
        turnOnSwitch={!isBypassed}
        onSwitch={onHandleBypass}
        isAdmin={isAdmin}
      />
      <div
        className={`flex flex-col max-h-[calc(100vh-156px)] w-full h-full p-2 ${
          !isEQGraphOpen ? "overflow-auto" : "overflow-hidden"
        }`}
      >
        <div className="flex m-auto">
          <div>
            <div className="flex gap-4 p-2">
              <Dial
                label="bits"
                size={80}
                degrees={260}
                min={1}
                max={8}
                step={1}
                value={bits}
                color={true}
                onChange={handlebitsChange}
              />
              <Dial
                label="wet"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.01}
                value={wet}
                color={true}
                onChange={handlewetChange}
              />
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      <AudioPlayerController
        readyPlay={isLoaded}
        isAboutVisible={isAboutVisible}
        audioIsPlaying={isPlaying}
        onPlayClick={handlePlayPause}
        onAboutClick={() => setIsAboutVisible(!isAboutVisible)}
        listAudio={listAudio}
        onVolumeChange={handleVolume}
        volume={volume}
        volumeMin={0}
        volumeMax={2}
        //onSeekTo={handleSeekClick}
        currentAudioIndex={currentAudioIndex}
        handleAudioItem={setCurrentAudioIndex}
      />
      <AboutContainer
        isVisible={isAboutVisible}
        onClose={() => setIsAboutVisible(!isAboutVisible)}
      >
        <h2 className="text-2xl font-bold mb-4">BitCrusher</h2>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
    </PlayerContainer>
  );
};

export default BitCrusher;
