import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import Header from "../../components/Header/Header";
import Dial from "../../components/Dial/Dial";

import Tone from "../../services/ToneLoader";
import { getEffectPlayerData } from "../../services/players";
import { useAuthParam, useController } from "../../hooks/custom-hooks";

const MultiBandCompressor = () => {
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { isAdmin } = useAuthParam();
  const {
    playerRef,
    isBypassed,
    isAboutVisible,
    isEQGraphOpen,
    currentAudioIndex,
    isPlaying,
    volume,
    listAudio,
    isLoaded,
    handleVolume,
    handleByPass,
    handlePlayPause,
    setListAudio,
    onChangeFileName,
    setCurrentTime,
    setCurrentAudioIndex,
    setIsAboutVisible,
  } = useController();

  // Compressor parameters state
  const [midThreshold, setmidThreshold] = useState(0);
  const [midRatio, setmidRatio] = useState(12);
  const [midAttack, setmidAttack] = useState(0.003);
  const [midRelease, setmidRelease] = useState("0.250");
  const [midKnee, setmidKnee] = useState(0);

  const [lowThreshold, setlowThreshold] = useState(0);
  const [lowRatio, setlowRatio] = useState(12);
  const [lowAttack, setlowAttack] = useState(0.003);
  const [lowRelease, setlowRelease] = useState("0.250");
  const [lowKnee, setlowKnee] = useState(0);

  const [highThreshold, sethighThreshold] = useState(0);
  const [highRatio, sethighRatio] = useState(12);
  const [highAttack, sethighAttack] = useState(0.003);
  const [highRelease, sethighRelease] = useState("0.250");
  const [highKnee, sethighKnee] = useState(0);

  const [highFrequency, sethighFrequency] = useState(4000);
  const [lowFrequency, setlowFrequency] = useState(250);

  const multiBandCompressorRef = useRef(null);

  const initialSetup = async (id) => {
    const { data } = await getEffectPlayerData("multibandCompressor", id);
    setListAudio(data.audio_files);

    if (data && data.audio_files && data.audio_files.length > 0) {
      setCurrentAudioIndex(0);
    }
  };

  useEffect(() => {
    initialSetup(id);
  }, [id]);

  useEffect(() => {
    if (currentAudioIndex === -1) {
      // If the audio URL is empty, stop any existing audio and avoid creating a new player
      if (playerRef.current) {
        playerRef.current.stop();
      }
      return;
    }

    if (!multiBandCompressorRef.current) {
      multiBandCompressorRef.current = new Tone.MultibandCompressor({
        low: {
          threshold: lowThreshold,
          ratio: lowRatio,
          attack: lowAttack,
          release: lowRelease,
          knee: lowKnee,
        },
        mid: {
          threshold: midThreshold,
          ratio: midRatio,
          attack: midAttack,
          release: midRelease,
          knee: midKnee,
        },
        high: {
          threshold: highThreshold,
          ratio: highRatio,
          attack: highAttack,
          release: highRelease,
          knee: highKnee,
        },
      }).toDestination();
    }

    onChangeFileName(multiBandCompressorRef);
  }, [currentAudioIndex]);

  useEffect(() => {
    return () => {
      // Clean up Tone objects when the component is unmounted
      playerRef?.current?.dispose();
      multiBandCompressorRef?.current?.dispose();
    };
  }, []);

  const onHandleBypass = () => handleByPass(multiBandCompressorRef);

  const handlemidThresholdChange = (value) => {
    const newmidThreshold = parseInt(value);
    setmidThreshold(newmidThreshold);

    multiBandCompressorRef.current.mid.threshold.value = newmidThreshold;
  };

  const handlemidRatioChange = (value) => {
    const newV = parseInt(value);
    setmidRatio(newV);

    multiBandCompressorRef.current.mid.midRatio = newV;
  };

  const handlemidAttackChange = (value) => {
    const newV = parseFloat(value);
    setmidAttack(newV);

    multiBandCompressorRef.current.mid.attack.rampTo(newV, 0.05);
  };

  const handlemidReleaseChange = (value) => {
    const newV = parseFloat(value);
    setmidRelease(newV);

    multiBandCompressorRef.current.mid.release.rampTo(newV, 0.05);
  };

  const handlemidKneeChange = (value) => {
    const newV = parseInt(value);
    setmidKnee(newV);

    multiBandCompressorRef.current.mid.knee.rampTo(newV, 0.5);
  };

  const handlelowThresholdChange = (value) => {
    const newlowThreshold = parseInt(value);
    setlowThreshold(newlowThreshold);

    multiBandCompressorRef.current.low.threshold.value = newlowThreshold;
  };

  const handlelowRatioChange = (value) => {
    const newV = parseInt(value);
    setlowRatio(newV);

    multiBandCompressorRef.current.low.lowRatio = newV;
  };

  const handlelowAttackChange = (value) => {
    const newV = parseFloat(value);
    setlowAttack(newV);

    multiBandCompressorRef.current.low.attack.rampTo(newV, 0.05);
  };

  const handlelowReleaseChange = (value) => {
    const newV = parseFloat(value);
    setlowRelease(newV);

    multiBandCompressorRef.current.low.release.rampTo(newV, 0.05);
  };

  const handlelowKneeChange = (value) => {
    const newV = parseInt(value);
    setlowKnee(newV);

    multiBandCompressorRef.current.low.knee.rampTo(newV, 0.5);
  };

  const handlehighThresholdChange = (value) => {
    const newhighThreshold = parseInt(value);
    sethighThreshold(newhighThreshold);

    multiBandCompressorRef.current.high.threshold.value = newhighThreshold;
  };

  const handlehighRatioChange = (value) => {
    const newV = parseInt(value);
    sethighRatio(newV);

    multiBandCompressorRef.current.high.highRatio = newV;
  };

  const handlehighAttackChange = (value) => {
    const newV = parseFloat(value);
    sethighAttack(newV);

    multiBandCompressorRef.current.high.attack.rampTo(newV, 0.05);
  };

  const handlehighReleaseChange = (value) => {
    const newV = parseFloat(value);
    sethighRelease(newV);

    multiBandCompressorRef.current.high.release.rampTo(newV, 0.05);
  };

  const handlehighKneeChange = (value) => {
    const newV = parseInt(value);
    sethighKnee(newV);

    multiBandCompressorRef.current.high.knee.rampTo(newV, 0.5);
  };

  const handlehighFrequencyChange = (value) => {
    const newV = parseInt(value);
    sethighFrequency(newV);

    multiBandCompressorRef.current.highFrequency.rampTo(newV, 0.5);
  };

  const handlelowFrequencyChange = (value) => {
    const newV = parseInt(value);
    setlowFrequency(newV);

    multiBandCompressorRef.current.lowFrequency.rampTo(newV, 0.5);
  };

  return (
    <PlayerContainer>
      <Header
        label={"MultiBandCompressor"}
        turnOnSwitch={!isBypassed}
        onSwitch={onHandleBypass}
        isAdmin={isAdmin}
      />
      <div
        className={`flex flex-col max-h-[calc(100vh-156px)] w-full h-full p-2 ${
          !isEQGraphOpen ? "overflow-auto" : "overflow-hidden"
        }`}
      >
        <div className="flex m-auto">
          <div>
            <div className="flex gap-4 p-2">
              <Dial
                label="highThreshold"
                size={80}
                degrees={260}
                min={-100}
                max={0}
                step={1}
                value={highThreshold}
                color={true}
                onChange={handlehighThresholdChange}
              />
              <Dial
                label="highRatio"
                size={80}
                degrees={260}
                min={1}
                max={20}
                step={1}
                value={highRatio}
                color={true}
                onChange={handlehighRatioChange}
              />
              <Dial
                label="highAttack"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={highAttack}
                color={true}
                onChange={handlehighAttackChange}
              />
              <Dial
                label="highRelease"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={highRelease}
                color={true}
                onChange={handlehighReleaseChange}
              />
              <Dial
                label="highKnee"
                size={80}
                degrees={260}
                min={0}
                max={40}
                step={1}
                value={highKnee}
                color={true}
                onChange={handlehighKneeChange}
              />
            </div>
            <div className="flex gap-4 p-2">
              <Dial
                label="midThreshold"
                size={80}
                degrees={260}
                min={-100}
                max={0}
                step={1}
                value={midThreshold}
                color={true}
                onChange={handlemidThresholdChange}
              />
              <Dial
                label="midRatio"
                size={80}
                degrees={260}
                min={1}
                max={20}
                step={1}
                value={midRatio}
                color={true}
                onChange={handlemidRatioChange}
              />
              <Dial
                label="midAttack"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={midAttack}
                color={true}
                onChange={handlemidAttackChange}
              />
              <Dial
                label="midRelease"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={midRelease}
                color={true}
                onChange={handlemidReleaseChange}
              />
              <Dial
                label="midKnee"
                size={80}
                degrees={260}
                min={0}
                max={40}
                step={1}
                value={midKnee}
                color={true}
                onChange={handlemidKneeChange}
              />
            </div>
            <div className="flex gap-4 p-2">
              <Dial
                label="lowThreshold"
                size={80}
                degrees={260}
                min={-100}
                max={0}
                step={1}
                value={lowThreshold}
                color={true}
                onChange={handlelowThresholdChange}
              />
              <Dial
                label="lowRatio"
                size={80}
                degrees={260}
                min={1}
                max={20}
                step={1}
                value={lowRatio}
                color={true}
                onChange={handlelowRatioChange}
              />
              <Dial
                label="lowAttack"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={lowAttack}
                color={true}
                onChange={handlelowAttackChange}
              />
              <Dial
                label="lowRelease"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={lowRelease}
                color={true}
                onChange={handlelowReleaseChange}
              />
              <Dial
                label="lowKnee"
                size={80}
                degrees={260}
                min={0}
                max={40}
                step={1}
                value={lowKnee}
                color={true}
                onChange={handlelowKneeChange}
              />
            </div>
            <div className="flex gap-4 p-2">
              <Dial
                label="highFrequency"
                size={80}
                degrees={260}
                min={0}
                max={20000}
                step={1}
                value={highFrequency}
                color={true}
                onChange={handlehighFrequencyChange}
              />
              <Dial
                label="lowFrequency"
                size={80}
                degrees={260}
                min={0}
                max={20000}
                step={1}
                value={lowFrequency}
                color={true}
                onChange={handlelowFrequencyChange}
              />
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      <AudioPlayerController
        readyPlay={isLoaded}
        isAboutVisible={isAboutVisible}
        audioIsPlaying={isPlaying}
        onPlayClick={handlePlayPause}
        onAboutClick={() => setIsAboutVisible(!isAboutVisible)}
        listAudio={listAudio}
        onVolumeChange={handleVolume}
        volume={volume}
        volumeMin={0}
        volumeMax={2}
        //onSeekTo={handleSeekClick}
        currentAudioIndex={currentAudioIndex}
        handleAudioItem={setCurrentAudioIndex}
      />
      <AboutContainer
        isVisible={isAboutVisible}
        onClose={() => setIsAboutVisible(!isAboutVisible)}
      >
        <h2 className="text-2xl font-bold mb-4">MultiBand Compressor</h2>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
    </PlayerContainer>
  );
};

export default MultiBandCompressor;
