import * as Tone from "tone";

const mutateToneCompressor = (originalComrpessor, zeroKnee=true, makeupGainValue) => {
	//modify compressor to cancel makeup gain using compensationGain and add adjustable makeup gain
	//note: makeup gain negation is imperfect when knee > 0
	originalComrpessor.name = "RiliumCompressor";
	if (zeroKnee) originalComrpessor.knee.value = 0;
	originalComrpessor.calculateCompensationGain = ( {threshold = originalComrpessor._threshold.value, knee = originalComrpessor._knee.value} = {} ) => { 
		const presumedMakeupGainDecibels = ( Math.abs(threshold) - ( (knee >= 4) ? ((knee-4)/2) : 0 ) );
		const newDefeatGain = Math.min( Tone.dbToGain( .5 * -presumedMakeupGainDecibels ), 1);
		return newDefeatGain;
	}
	for (const replaceProperty of ["knee", "threshold"]) {
		const originalPropertyNewName = "_"+replaceProperty;
		originalComrpessor[originalPropertyNewName] = originalComrpessor[replaceProperty];
		Object.defineProperty(originalComrpessor, replaceProperty, {
			get: function() {
				return {
					get value() {
						return originalComrpessor[originalPropertyNewName].value;
					},
					set value(newValue) {
						originalComrpessor[originalPropertyNewName].value = newValue;
						originalComrpessor.compensationGain.gain.value = originalComrpessor.calculateCompensationGain( {[replaceProperty]: newValue} );
					},
					rampTo: (newValue, time) => {
						originalComrpessor[originalPropertyNewName].rampTo(newValue, time);
						originalComrpessor.compensationGain.gain.rampTo( originalComrpessor.calculateCompensationGain( {[replaceProperty]: newValue} ), time );
					},
					dispose: () => {
						return originalComrpessor[originalPropertyNewName].dispose();
					}
				}
			}
		});
	}
	originalComrpessor.compensationGain = new Tone.Gain(originalComrpessor.calculateCompensationGain());
	originalComrpessor._compressor.disconnect();
	originalComrpessor.makeupGain = new Tone.Volume(makeupGainValue);
	Tone.connect(originalComrpessor._compressor, originalComrpessor.compensationGain);
	originalComrpessor.compensationGain.connect(originalComrpessor.makeupGain);
	originalComrpessor.output = originalComrpessor.makeupGain;
	//console.log("using RiliumCompressor", originalComrpessor);
}

Object.defineProperty(Tone, "RiliumCompressor", {
	get: function() {
		return function(optionsOrThreshold, ratio) {
			let useFirstParamOnly, makeupValue;
			if (Tone.isObject(optionsOrThreshold)) {
				if (!optionsOrThreshold.hasOwnProperty("knee")) optionsOrThreshold.knee = 0;
				if (optionsOrThreshold.hasOwnProperty("makeup")) {
					makeupValue = optionsOrThreshold.makeup;
					delete optionsOrThreshold.makeup;
				}
				useFirstParamOnly = true;
			} else if (ratio === undefined) {
				optionsOrThreshold ??= {knee: 0};
				useFirstParamOnly = true;
			}
			const newCompressor = new Tone.Compressor(...(useFirstParamOnly ? [optionsOrThreshold] : [optionsOrThreshold, ratio])); //work around Tone bug that ignores options in 1st param if number of params > 1
			mutateToneCompressor(newCompressor, false, makeupValue);
			return newCompressor;
		}
	}
});

Object.defineProperty(Tone, "RiliumLimiter", {
	get: function() {
		return function(threshold) {
			const newLimiter = new Tone.Limiter(threshold);
			newLimiter.name = "RiliumLimiter";
			mutateToneCompressor(newLimiter._compressor);
			delete newLimiter.threshold;
			newLimiter.threshold = newLimiter._compressor.threshold;
			return newLimiter;
		}
	}
});

Tone.getDestination().volume.units = "gain";

export default Tone;




