import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import Header from "../../components/Header/Header";
import Dial from "../../components/Dial/Dial";

import Tone from "../../services/ToneLoader";
import { getEffectPlayerData } from "../../services/players";
import { useAuthParam, useController } from "../../hooks/custom-hooks";

const Phaser = () => {
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { isAdmin } = useAuthParam();
  const {
    playerRef,
    isBypassed,
    isAboutVisible,
    isEQGraphOpen,
    currentAudioIndex,
    isPlaying,
    currentTime,
    volume,
    listAudio,
    isLoaded,
    handleVolume,
    handleByPass,
    handlePlayPause,
    setListAudio,
    onChangeFileName,
    setCurrentTime,
    setCurrentAudioIndex,
    setIsAboutVisible,
  } = useController();

  const [frequency, setfrequency] = useState(0.5);
  const [octaves, setoctaves] = useState(3);
  const [Q, setQ] = useState(2);
  const [baseFrequency, setbaseFrequency] = useState(350);
  const [wet, setWet] = useState(0.5);

  const phaserRef = useRef(null);

  const initialSetup = async (id) => {
    const { data } = await getEffectPlayerData("phaser", id);
    setListAudio(data.audio_files);

    if (data && data.audio_files && data.audio_files.length > 0) {
      setCurrentAudioIndex(0);
    }
  };

  useEffect(() => {
    initialSetup(id);
  }, [id]);

  useEffect(() => {
    if (currentAudioIndex === -1) {
      // If the audio URL is empty, stop any existing audio and avoid creating a new player
      if (playerRef.current) {
        playerRef.current.stop();
      }
      return;
    }

    if (!phaserRef.current) {
      phaserRef.current = new Tone.Phaser({
        frequency,
        octaves,
        baseFrequency,
        Q,
        wet,
      }).toDestination();
      //console.log(phaserRef.current);
    }

    onChangeFileName(phaserRef);
  }, [currentAudioIndex]);

  useEffect(() => {
    return () => {
      // Clean up Tone objects when the component is unmounted
      playerRef?.current?.dispose();
      phaserRef?.current?.dispose();
    };
  }, []);

  const onHandleBypass = () => handleByPass(phaserRef);

  const handleFrequencyChange = (value) => {
    const newfrequency = parseInt(value);
    setfrequency(newfrequency);

    phaserRef.current.frequency.rampTo(newfrequency, 0.05);
  };

  const handleOctavesChange = (value) => {
    const newV = parseInt(value);
    setoctaves(newV);

    phaserRef.current.octaves = newV;
  };

  const handleQChange = (value) => {
    const newV = parseFloat(value);
    setQ(newV);

    phaserRef.current.Q.rampTo(newV, 0.05);
  };

  const handleBaseFrequencyChange = (value) => {
    const newV = parseInt(value);
    setbaseFrequency(newV);

    phaserRef.current.baseFrequency = newV;
  };

  const handleWetChange = (value) => {
    const newV = parseFloat(value);
    setWet(newV);

    phaserRef.current.wet.rampTo(newV, 0.05);
  };

  return (
    <PlayerContainer>
      <Header
        label={"Phaser"}
        turnOnSwitch={!isBypassed}
        onSwitch={onHandleBypass}
        isAdmin={isAdmin}
      />
      <div
        className={`flex flex-col max-h-[calc(100vh-156px)] w-full h-full p-2 ${
          !isEQGraphOpen ? "overflow-auto" : "overflow-hidden"
        }`}
      >
        <div className="flex m-auto">
          <div>
            <div className="flex gap-5 p-2">
              <Dial
                label="frequency"
                size={80}
                degrees={260}
                min={0.1}
                max={20}
                step={0.1}
                value={frequency}
                color={true}
                onChange={handleFrequencyChange}
              />
              <Dial
                label="octaves"
                size={80}
                degrees={260}
                min={0}
                max={10}
                step={0.1}
                value={octaves}
                color={true}
                onChange={handleOctavesChange}
              />
              <Dial
                label="Q"
                size={80}
                degrees={260}
                min={0.1}
                max={5}
                step={0.01}
                value={Q}
                color={true}
                onChange={handleQChange}
              />
              <Dial
                label="baseFrequency"
                size={80}
                degrees={260}
                min={20}
                max={15000}
                step={1}
                value={baseFrequency}
                color={true}
                onChange={handleBaseFrequencyChange}
              />
              <Dial
                label="wet"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.01}
                value={wet}
                color={true}
                onChange={handleWetChange}
              />
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      <AudioPlayerController
        readyPlay={isLoaded}
        isAboutVisible={isAboutVisible}
        audioIsPlaying={isPlaying}
        onPlayClick={handlePlayPause}
        onAboutClick={() => setIsAboutVisible(!isAboutVisible)}
        listAudio={listAudio}
        onVolumeChange={handleVolume}
        volume={volume}
        volumeMin={0}
        volumeMax={2}
        //onSeekTo={handleSeekClick}
        currentAudioIndex={currentAudioIndex}
        handleAudioItem={setCurrentAudioIndex}
      />
      <AboutContainer
        isVisible={isAboutVisible}
        onClose={() => setIsAboutVisible(!isAboutVisible)}
      >
        <h2 className="text-2xl font-bold mb-4">Phaser</h2>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
    </PlayerContainer>
  );
};

export default Phaser;
