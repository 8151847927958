import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import Header from "../../components/Header/Header";
import Dial from "../../components/Dial/Dial";

import Tone from "../../services/ToneLoader";
import { getEffectPlayerData } from "../../services/players";
import { useAuthParam, useController } from "../../hooks/custom-hooks";

const MidCompressor = () => {
  const location = useLocation();
  const id = location.pathname.split("/").pop();
  const { isAdmin } = useAuthParam();
  const {
    playerRef,
    isBypassed,
    isAboutVisible,
    isEQGraphOpen,
    currentAudioIndex,
    isPlaying,
    volume,
    listAudio,
    isLoaded,
    handleVolume,
    handleByPass,
    handlePlayPause,
    setListAudio,
    onChangeFileName,
    setCurrentTime,
    setCurrentAudioIndex,
    setIsAboutVisible,
  } = useController();

  // Compressor parameters state
  const [midThreshold, setmidThreshold] = useState(0);
  const [midRatio, setmidRatio] = useState(12);
  const [midAttack, setmidAttack] = useState(0.003);
  const [midRelease, setmidRelease] = useState("0.250");
  const [midKnee, setmidKnee] = useState(0);

  const [sideThreshold, setsideThreshold] = useState(0);
  const [sideRatio, setsideRatio] = useState(12);
  const [sideAttack, setsideAttack] = useState(0.003);
  const [sideRelease, setsideRelease] = useState("0.250");
  const [sideKnee, setsideKnee] = useState(0);

  const midSideCompressorRef = useRef(null);

  const initialSetup = async (id) => {
    const { data } = await getEffectPlayerData("midSideCompressor", id);
    setListAudio(data.audio_files);

    if (data && data.audio_files && data.audio_files.length > 0) {
      setCurrentAudioIndex(0);
    }
  };

  useEffect(() => {
    initialSetup(id);
  }, [id]);

  useEffect(() => {
    if (currentAudioIndex === -1) {
      // If the audio URL is empty, stop any existing audio and avoid creating a new player
      if (playerRef.current) {
        playerRef.current.stop();
      }
      return;
    }

    if (!midSideCompressorRef.current) {
      midSideCompressorRef.current = new Tone.MidSideCompressor({
        mid: {
          threshold: midThreshold,
          ratio: midRatio,
          attack: midAttack,
          release: midRelease,
          knee: midKnee,
        },
        side: {
          threshold: sideThreshold,
          ratio: sideRatio,
          attack: sideAttack,
          release: sideRelease,
          knee: sideKnee,
        },
      }).toDestination();
    }

    onChangeFileName(midSideCompressorRef);
  }, [currentAudioIndex]);

  useEffect(() => {
    return () => {
      // Clean up Tone objects when the component is unmounted
      playerRef?.current?.dispose();
      midSideCompressorRef?.current?.dispose();
    };
  }, []);

  const onHandleBypass = () => handleByPass(midSideCompressorRef);

  const handlemidThresholdChange = (value) => {
    const newmidThreshold = parseInt(value);
    setmidThreshold(newmidThreshold);

    midSideCompressorRef.current.mid.threshold.value = newmidThreshold;
  };

  const handlemidRatioChange = (value) => {
    const newV = parseInt(value);
    setmidRatio(newV);

    midSideCompressorRef.current.mid.midRatio = newV;
  };

  const handlemidAttackChange = (value) => {
    const newV = parseFloat(value);
    setmidAttack(newV);

    midSideCompressorRef.current.mid.attack.rampTo(newV, 0.05);
  };

  const handlemidReleaseChange = (value) => {
    const newV = parseFloat(value);
    setmidRelease(newV);

    midSideCompressorRef.current.mid.release.rampTo(newV, 0.05);
  };

  const handlemidKneeChange = (value) => {
    const newV = parseInt(value);
    setmidKnee(newV);

    midSideCompressorRef.current.mid.knee.rampTo(newV, 0.5);
  };

  const handlesideThresholdChange = (value) => {
    const newsideThreshold = parseInt(value);
    setsideThreshold(newsideThreshold);

    midSideCompressorRef.current.side.threshold.value = newsideThreshold;
  };

  const handlesideRatioChange = (value) => {
    const newV = parseInt(value);
    setsideRatio(newV);

    midSideCompressorRef.current.side.sideRatio = newV;
  };

  const handlesideAttackChange = (value) => {
    const newV = parseFloat(value);
    setsideAttack(newV);

    midSideCompressorRef.current.side.attack.rampTo(newV, 0.05);
  };

  const handlesideReleaseChange = (value) => {
    const newV = parseFloat(value);
    setsideRelease(newV);

    midSideCompressorRef.current.side.release.rampTo(newV, 0.05);
  };

  const handlesideKneeChange = (value) => {
    const newV = parseInt(value);
    setsideKnee(newV);

    midSideCompressorRef.current.side.knee.rampTo(newV, 0.5);
  };

  return (
    <PlayerContainer>
      <Header
        label={"MidsideCompressor"}
        turnOnSwitch={!isBypassed}
        onSwitch={onHandleBypass}
        isAdmin={isAdmin}
      />
      <div
        className={`flex flex-col max-h-[calc(100vh-156px)] w-full h-full p-2 ${
          !isEQGraphOpen ? "overflow-auto" : "overflow-hidden"
        }`}
      >
        <div className="flex m-auto">
          <div>
            <div className="flex gap-4 p-2">
              <Dial
                label="midThreshold"
                size={80}
                degrees={260}
                min={-100}
                max={0}
                step={1}
                value={midThreshold}
                color={true}
                onChange={handlemidThresholdChange}
              />
              <Dial
                label="midRatio"
                size={80}
                degrees={260}
                min={1}
                max={20}
                step={1}
                value={midRatio}
                color={true}
                onChange={handlemidRatioChange}
              />
              <Dial
                label="midAttack"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={midAttack}
                color={true}
                onChange={handlemidAttackChange}
              />
              <Dial
                label="midRelease"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={midRelease}
                color={true}
                onChange={handlemidReleaseChange}
              />
              <Dial
                label="midKnee"
                size={80}
                degrees={260}
                min={0}
                max={40}
                step={1}
                value={midKnee}
                color={true}
                onChange={handlemidKneeChange}
              />
            </div>
            <div className="flex gap-4 p-2">
              <Dial
                label="sideThreshold"
                size={80}
                degrees={260}
                min={-100}
                max={0}
                step={1}
                value={sideThreshold}
                color={true}
                onChange={handlesideThresholdChange}
              />
              <Dial
                label="sideRatio"
                size={80}
                degrees={260}
                min={1}
                max={20}
                step={1}
                value={sideRatio}
                color={true}
                onChange={handlesideRatioChange}
              />
              <Dial
                label="sideAttack"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={sideAttack}
                color={true}
                onChange={handlesideAttackChange}
              />
              <Dial
                label="sideRelease"
                size={80}
                degrees={260}
                min={0}
                max={1}
                step={0.001}
                value={sideRelease}
                color={true}
                onChange={handlesideReleaseChange}
              />
              <Dial
                label="sideKnee"
                size={80}
                degrees={260}
                min={0}
                max={40}
                step={1}
                value={sideKnee}
                color={true}
                onChange={handlesideKneeChange}
              />
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
      <AudioPlayerController
        readyPlay={isLoaded}
        isAboutVisible={isAboutVisible}
        audioIsPlaying={isPlaying}
        onPlayClick={handlePlayPause}
        onAboutClick={() => setIsAboutVisible(!isAboutVisible)}
        listAudio={listAudio}
        onVolumeChange={handleVolume}
        volume={volume}
        volumeMin={0}
        volumeMax={2}
        //onSeekTo={handleSeekClick}
        currentAudioIndex={currentAudioIndex}
        handleAudioItem={setCurrentAudioIndex}
      />
      <AboutContainer
        isVisible={isAboutVisible}
        onClose={() => setIsAboutVisible(!isAboutVisible)}
      >
        <h2 className="text-2xl font-bold mb-4">MidCompressor</h2>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
    </PlayerContainer>
  );
};

export default MidCompressor;
